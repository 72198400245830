import { InputHTMLAttributes, forwardRef } from 'react';
import { cx } from '../utils/helpers';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, ...nativeProps }, ref) => {
  return (
    <input
      ref={ref}
      className={cx(
        'flex items-center h-12 px-4 w-64 bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2',
        className,
      )}
      {...nativeProps}
    />
  );
});

Input.displayName = 'Input';

export default Input;
