import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { cx } from '../utils/helpers';

interface NavItemProps {
  children: ReactNode;
  className?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  to: string;
}

const NavItem = ({ children, className, leftIcon, rightIcon, to }: NavItemProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cx(
          'flex items-center gap-2 w-full px-3 mt-2 h-12 rounded hover:bg-gray-700 hover:text-gray-300',
          className,
          isActive ? 'bg-gray-700 text-gray-300' : '',
        )
      }
    >
      {leftIcon}
      <span className="text-sm">{children}</span>
      {rightIcon}
    </NavLink>
  );
};

export default NavItem;
