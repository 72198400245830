/**
 * Joins all provided css classes
 * @param args list of all css classes
 * @returns space separated css classes
 */
export function cx(...args: unknown[]) {
  return args
    .flat()
    .filter((x) => typeof x === 'string')
    .join(' ')
    .trim();
}
