import { Form } from 'react-router-dom';

import BarChartIcon from '../icons/bar-chart-icon';
import CubeIcon from '../icons/cube-icon';
import HomeIcon from '../icons/home-icon';
import LensIcon from '../icons/lens-icon';
import SettingsIcon from '../icons/settings-icon';
import NavItem from './nav-item';

const AppNav = () => {
  return (
    <div className="flex flex-col flex-none items-center sm:w-[220px] w-full overflow-hidden text-gray-400 bg-gray-900">
      <div className="flex items-center w-full px-3 h-16 border-b border-gray-700">
        <CubeIcon className="w-8 h-8 fill-current" />
        <span className="ml-3 text-lg font-bold">Question Bank</span>
      </div>
      <div className="w-full px-2">
        <div className="flex flex-col items-center w-full mt-16">
          <NavItem to="/" leftIcon={<HomeIcon />}>
            Home
          </NavItem>
          <NavItem to="/search" leftIcon={<LensIcon />}>
            Search
          </NavItem>
          <NavItem to="/insights" leftIcon={<BarChartIcon />}>
            Insights
          </NavItem>
          <NavItem to="/settings" leftIcon={<SettingsIcon />}>
            Settings
          </NavItem>
        </div>
      </div>
      <Form className="w-full mt-auto" method="post" action="/logout">
        <button className="flex items-center justify-center w-full h-16 bg-gray-800 hover:bg-gray-700 hover:text-gray-300 text-sm font-medium">
          Logout
        </button>
      </Form>
    </div>
  );
};

export default AppNav;
