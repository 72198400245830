import { ButtonHTMLAttributes, forwardRef } from 'react';
import { cx } from '../utils/helpers';

type ButtonVariant = 'primary';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** @default 'primary' */
  variant?: ButtonVariant;
}

const variantClasses: { [key in ButtonVariant]: string } = {
  primary: 'text-blue-100 bg-blue-600 hover:bg-blue-700',
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'primary', ...nativeProps }, ref) => {
    return (
      <button
        ref={ref}
        className={cx(
          'flex items-center justify-center h-12 px-6 w-64 mt-8 rounded font-semibold text-sm',
          variantClasses[variant],
          className,
        )}
        {...nativeProps}
      />
    );
  },
);

Button.displayName = 'Button';

export default Button;
