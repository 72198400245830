import { Outlet } from 'react-router-dom';
import AppFooter from './app-footer';
import AppNav from './app-nav';

const AppLayout = () => {
  return (
    <>
      <div className="w-full min-h-screen flex flex-col sm:flex-row flex-grow overflow-hidden">
        <AppNav />
        <main role="main" className="w-full h-full flex-grow p-3 overflow-auto">
          <Outlet />
        </main>
      </div>
      <AppFooter />
    </>
  );
};

export default AppLayout;
