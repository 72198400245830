import { LabelHTMLAttributes } from 'react';
import { cx } from '../utils/helpers';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {}

const Label = ({ className, ...nativeProps }: LabelProps) => {
  return (
    <label className={cx('font-semibold text-xs mt-3 first:mt-0', className)} {...nativeProps} />
  );
};

export default Label;
