import { Form, Link } from 'react-router-dom';
import Button from '../../components/button';
import Input from '../../components/input';
import Label from '../../components/label';

export const Login = () => {
  return (
    <main className="flex flex-col items-center justify-center w-screen h-screen bg-white md:bg-gray-200 text-gray-700">
      <h1 className="font-bold text-2xl">Welcome Back</h1>
      <Form
        method="post"
        className="flex flex-col bg-transparent md:bg-white rounded md:shadow-lg p-6 md:p-12 mt-6 md:mt-12"
      >
        <Label htmlFor="usernameField">Username or Email</Label>
        <Input id="usernameField" type="text" name="username" />
        <Label htmlFor="passwordField">Password</Label>
        <Input id="passwordField" type="password" name="password" />
        <Button type="submit">Login</Button>
        <div className="flex mt-6 justify-center text-xs">
          <Link className="text-blue-400 hover:text-blue-500" to="/forgot-password">
            Forgot Password
          </Link>
          <span className="mx-2 text-gray-300">/</span>
          <Link className="text-blue-400 hover:text-blue-500" to="/signup">
            Sign Up
          </Link>
        </div>
      </Form>
    </main>
  );
};
