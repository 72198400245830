import { createBrowserRouter, redirect } from 'react-router-dom';

import AppLayout from './components/app-layout';
import Home from './pages/home';
import { Login } from './pages/login';
import { loginAction } from './pages/login/login.action';
import Insights from './pages/insights';
import Settings from './pages/settings';
import Search from './pages/search';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
    action: loginAction,
  },
  {
    path: '/logout',
    async action() {
      // TODO: clear auth token
      return redirect('/login');
    },
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/insights',
        element: <Insights />,
      },
      {
        path: '/search',
        element: <Search />,
      },
      {
        path: '/settings',
        element: <Settings />,
      },
    ],
  },
]);

export default router;
